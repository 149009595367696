import React from 'react';
import styled from 'styled-components';

const BOX = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  transition: transform ease-in 3s, opacity linear 3s;
`;

const IMG = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  padding: 50px;
  
  background-image: url(${props => props.image}?h=${props => props.height}&w=${props => props.width}); //?auto=compress&fit=crop
  background-size: ${props => props.backgroundSize};
  background-position: center center;
  background-repeat: no-repeat;
  
  backdrop-filter: blur(20px); 
`;
const BACKGROUND = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-image: url(${props => props.image}?h=${props => props.height}&w=${props => props.width}); //?auto=compress&fit=crop
  // background-size: ${props => props.backgroundSize};
  background-position: center center;
  background-repeat: no-repeat;
  
  zoom: 3;
`;

const Img = (props) => {
  const { photo, width, height, fade, zoom, opacity } = props;

  if (photo?.src?.original === undefined) {
    return null;
  }

  return (<BOX style={fade ? {opacity, transform: `scale(${zoom})`} : {}}>
    <BACKGROUND
      image={photo?.src?.original}
      width={width}
      height={height}
      backgroundSize={photo.scale > 0.8 && photo.scale < 1.2 ? 'cover' : 'contain'}
    />
    <IMG
      image={photo?.src?.original}
      width={width}
      height={height}
      backgroundSize={photo.scale > 0.8 && photo.scale < 1.2 ? 'cover' : 'contain'}
    />
  </BOX>);
}

export default Img;
