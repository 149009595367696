import React, {useState} from 'react';
import styled from 'styled-components';
import Background from './components/Background';

const SEARCH = styled.input`
  position: fixed;
  top: 25px;
  left: 25px;
  width: 200px;
  z-index: 99;
  padding: 15px;
  font-size: 18px;
  display: block;
  border: none;
  outline: 0;
  border-radius: 4px;
  opacity: .7;
`;

function App() {

  const [query, setQuery] = useState(null);
  return (
    <div className="App">
      <SEARCH type={'text'} placeholder={'by Pexels'} onKeyPress={(e) => {
        if(e.key === 'Enter') {
          setQuery(e.target.value.trim() === '' ? null : e.target.value.trim());
        }
      }}/>
      <Background query={query} setQuery={setQuery}/>
    </div>
  );
}

export default App;
